* {
  color: white;
  text-align: center;
  font-family: "Jost";
}
:root {
  --color1: #1abefd;
  --color2: #0457ff;
  --color3: #17adfe;
  --color4: #121212;
  --color5: #242424;
  --color6: #434343;
}

.signUpBtn {
  background: linear-gradient(
    90.97deg,
    var(--color1) 0.84%,
    var(--color3) 33.88%,
    var(--color2) 100.26%
  );
  border: none;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1.6rem;
  border-radius: 0.55rem;
  font-family: "Jost";
  width: fit-content;
}

body,
html {
  background: var(--color4);
  height: 100%;
  overflow-x: hidden;
}

.heroVideo {
  position: fixed;
  opacity: 0.3;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  z-index: 0;
  left: 0;
}

.footerImg {
  opacity: 0.5;
}

.sepp {
  margin-top: 40vh;
  margin-bottom: 40vh;
  height: 37vh;
  width: 100%;
  background: linear-gradient(180deg, #b9b9b9 0%, #7e7e7e 100%);
}

.productTitle,
.waitingListTitle,
.waitingListSubtitle,
.FAQTitle,
.cookiesTitle {
  margin-top: 3rem;
  font-size: 4rem;
  font-family: "Jost";
  letter-spacing: -0.04em;
  font-style: normal;
  font-weight: 700;
  line-height: 121%;
  color: #ffffff;
}

.waitingListSubtitle {
  font-size: 1.8rem;
}
.productTitle {
  margin-top: 0;
  width: 50rem;
}
.cookiesTitle {
  font-size: 1.8rem;
  margin-top: 0;
}

.FAQTitle {
  font-size: 2.8rem;
  width: 100%;
}

.waitingListTitle {
  font-size: 2.8rem;
  margin: 5rem;
}

.productText {
  font-size: 1.3rem;
  margin: 1rem;
}

.startBtn,
.startBtnIndent {
  font-family: "Jost";
  font-weight: 800;
  border-radius: 0;
  background: linear-gradient(
    90.97deg,
    var(--color1) 0.84%,
    var(--color3) 30.88%,
    var(--color2) 100.26%
  );
  color: white;
  font-weight: bold;
  border: none;
  width: 15rem;
  height: 3rem;
  position: relative;
  clip-path: polygon(0 0, 95% 0, 100% 20%, 100% 100%, 5% 100%, 0 78%);
  text-transform: uppercase;
  z-index: 2;
  transition: 0.2s ease;
  top: 0;
  left: 0;
}

.startBtnIndent {
  opacity: 0.45;
}
.startBtnIndentInner {
  border-radius: 0;
  background: var(--color5);
  font-weight: bold;
  border: none;
  width: 14.9rem;
  height: 2.9rem;
  position: relative;
  clip-path: polygon(0 0, 95% 0, 100% 20%, 100% 100%, 5% 100%, 0 78%);
  text-transform: uppercase;
  z-index: 2;
}

.startBtnIndent {
  position: absolute;
  top: 1.3rem;
  left: 0.5rem;
  z-index: 0;
}
.startBtnIndentInner {
  position: absolute;
  top: 1.35rem; /* Offset from the top */
  left: 0.55rem; /* Offset from the left */
  z-index: 1; /* Ensure it appears behind the startBtn */
}

.phoneBlack {
  margin-top: -24%;
}
.phoneWhite {
  margin-top: -6%;
}
.iconDiv a {
  display: flex;
}

.productTitleB {
  font-family: "Jost";

  font-style: normal;
  font-weight: 700;
  line-height: 172%;
  letter-spacing: -0.04em;
  background: linear-gradient(90deg, #8e8e8e 15%, #e5e5e5 60%, #505050 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: left;
  font-size: 2.7rem;
  width: fit-content;
}
.productPoint {
  width: 57%;
  margin: 4rem;
}

.productPoint p {
  text-align: left;
  font-size: 1.6rem;
  letter-spacing: -0.04em;
  width: 100%;
  border-top: 1px solid white;
  padding: 2rem 0;
}

.waitingListTitle,
.waitingListSubtitle,
.FAQTitle,
.cookiesTitle,
.waitingListContainer,
.footerContainer,
.myNav,
.FAQContainer {
  width: 67%;
}
.myNav {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.waitingListContainer p {
  width: 75%;
  font-size: 1.4rem;
}
.waitingListContainer {
  padding: 3rem 0;
  border-radius: 2rem;
}
.waitinglistInput {
  box-sizing: border-box;
  background: var(--color5);
  border: 1px solid var(--color6);
  box-shadow: 0px 1.12094px 1.12094px rgba(0, 0, 0, 0.25);
  width: 85%;
  text-align: left;
  margin: 1rem;
  font-size: 1.1rem;
  border-radius: 1rem;
  padding: 1rem;
}

.form {
  width: 50%;
  padding: 3rem;
  background-color: var(--color5);
  margin: 3rem;
}

.form input {
  border: none;
  border-bottom: 2px var(--color6) solid;
  background-color: transparent;
  text-align: left;
  color: white;
}

.form textarea,
.form textarea:focus {
  background-color: transparent;
  border: 2px solid var(--color6);
  color: white;
  text-align: left;
}

.confirmBtn {
  background-color: black;
  border: none;
  transition: 0.3s ease-out;
}
.successBtn {
  transition: none;
  background-color: white;
  color: white;
}

.dividerLine {
  border: 1px white solid;
  width: 100%;
}

.footer {
  background-color: black;
  position: relative;
}

.stayConnectedDiv {
  display: flex;
}

.navlink {
  color: white;
  font-size: 1.4rem;
  margin: 0 1.5rem;
  letter-spacing: 0.1rem;
  background-color: transparent;
  transition: 0.2s ease;
}

.navContainer {
  z-index: 1000;
  padding: 0.4rem 0;
}

.navbarTranslucent {
  background-color: transparent;
}
.navbarOpaque {
  background-color: black;
}

.form input:focus {
  border: none;
  outline: none;
  background-color: transparent;
  box-shadow: none;
  border-bottom: 2px var(--color6) solid;
  color: white;
}

.form input::placeholder {
  color: #a4a4a4;
}

.form label {
  color: #a4a4a4;
  font-size: 1rem;
}

.footerContainer p {
  text-align: left;
  font-size: 0.7rem;
  margin: 0.5rem;
}

.faq-header {
  font-size: 1rem;
  background-color: var(--color5);
  color: white;
  border: 2px solid transparent;
  font-weight: bold;
}

.faq-body {
  background-color: var(--color5);
  color: white;
}

.faq-header:not(.collapsed) {
  background: linear-gradient(
    90.97deg,
    var(--color1) 0.84%,
    var(--color3) 40.88%,
    var(--color2) 100.26%
  );
  border-color: white;
  color: white;
}

.accordion-button::after {
  filter: invert(1);
}

.faq-card {
  background-color: var(--color5);
  border: 2px var(--color5) solid;
}
.accordion-button:not(.collapsed),
.accordion-button:focus {
  box-shadow: none;
}

.accordion-body {
  line-height: 1.2rem;
}

.answerOptions {
  background-color: var(--color5);
  transition: 0.5s ease;
  cursor: pointer;
}

.glow {
  filter: drop-shadow(0 0 5px white);
}

.answerInput {
  background-color: var(--color5);
  color: white;
  padding-top: 0;
  padding-bottom: 0;
}

.answerInput::placeholder {
  color: var(--color6);
}

.answerInput:focus {
  box-shadow: none;
  background-color: var(--color5);
  color: white;
}
.iconDiv svg {
  cursor: pointer;
  margin-left: 1rem;
  height: 2rem;
}

.iconDiv svg path {
  fill: white;
  transition: 0.3s ease;
}

.bgIcon {
  position: absolute;
  z-index: 0;
  scale: 0.7;
  margin-top: 9rem;
}

.icon1 {
  translate: -400px 200px;
}

.icon2 {
  translate: -600px -100px;
}

.icon3 {
  translate: 200px 300px;
}

.icon4 {
  translate: 700px -150px;
}

.icon5 {
  translate: 500px 100px;
}

.productSection {
  position: relative;
  background-image: url("./assets/productSectionImg.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.questionSection {
  position: relative;
  background: var(--color4);

  background-size: cover;
  background-position: center;
}

.heroSection {
  position: relative;

  height: 100vh;
}

.faqSection {
  position: relative;
  background: var(--color4);

  background-size: cover;
  background-position: center;
}

.contactSection {
  position: relative;
  background: var(--color4);

  background-size: cover;
  background-position: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.carouselSection {
  position: relative;
  background: var(--color4);
}

.bgOverlay {
  background-color: #121212c9;
}

.transition {
  transition: opacity 0.5s ease-in-out;
}

.fade {
  opacity: 0;
}

.show {
  opacity: 1;
}

.heroLogo,
.heroLogobg1,
.heroLogobg2 {
  translate: 0px 100%;
  scale: 3;
  opacity: 0;
  transition: 1.5s ease;
  transform-origin: 0% 50%;
}
.heroLogo.visible,
.heroLogobg1.visible,
.heroLogobg2.visible {
  translate: 0;
  scale: 1;
}
.heroLogo.visible {
  opacity: 1;
}
.heroLogobg1 {
  opacity: 0.1;
  animation: scaleAnimation1 3s ease-in-out 0s 2 normal both;
}
@keyframes scaleAnimation1 {
  0%,
  100% {
    width: 60%;
  }
  50% {
    width: 70%;
  }
}
.heroLogobg2 {
  opacity: 0.05;
  animation: scaleAnimation2 3s ease-in-out 0s 2 normal both;
}

@keyframes scaleAnimation2 {
  0%,
  100% {
    width: 60%;
  }
  50% {
    width: 80%;
  }
}

@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.productPoint {
  opacity: 0; /* Start hidden */
  transition: opacity 0.5s, transform 0.8s; /* Smooth transition */
}

.productPoint.fade-in {
  animation: fadeInFromLeft 0.8s forwards; /* Trigger the animation */
}

.bPhone {
  position: absolute;
  width: 20%;
  left: 30%;
  top: 0;
  translate: 0 -150px;
}

.wPhone {
  position: absolute;
  width: 20%;
  right: 30%;
  top: 0;
  translate: 0 -80px;
}
.productImg {
  margin-top: 15rem;
  margin-bottom: 9rem;
}

.CookiesSection {
  position: fixed;
  bottom: 0;
  background-color: black;
  z-index: 10000;
}

.h-fit-content {
  height: fit-content;
}

.form-control {
  font-size: 1rem;
}

.stayConnectedText {
  font-size: 1.95rem;
  font-family: "Jost";
  letter-spacing: -0.04em;
  font-style: normal;
  font-weight: 700;
  line-height: 121%;
  color: #ffffff;
  padding: 0.5rem;
  margin-right: 1.5rem;
}

.custom-progress-bar {
  width: 50%;
}

.custom-progress-bar .progress-bar {
  background-color: white !important; /* White progress bar */
  border-radius: 1rem;
  filter: drop-shadow(0 0 5px white);
}

.progress {
  background-color: var(--color5); /* Grey background bar */
  border: 1px solid var(--color6);
  border-radius: 1rem;
  width: 10rem;
}

.footerBtn {
  transition: 0.2s ease;
}
@media (max-width: 991px) {
  .navbarTranslucent {
    background-color: black;
  }
}
@media (max-width: 750px) {
  .productTitle,
  .waitingListTitle,
  .waitingListSubtitle,
  .FAQTitle,
  .cookiesTitle,
  .waitingListContainer,
  .footerContainer,
  .myNav,
  .FAQContainer {
    width: 90%;
    font-size: 1.5rem;
  }
  /* Custom CSS example to change the hamburger icon color */
  /* Custom CSS to make the hamburger menu icon thicker */
  .navbar-toggler-icon {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"%3E%3Cpath stroke="white" stroke-width="3" d="M4 6h22M4 15h22M4 24h22" /%3E%3C/svg%3E');
  }

  .heroVideo {
    position: static;
    opacity: 0.3;
    height: auto;
    width: 100vw;
    object-fit: cover;
    object-position: center;
    margin-top: 3rem;
  }
  .heroSection {
    height: auto;
  }
  .productTitle {
    font-size: 2.5rem;
    line-height: 1.5;
  }
  .productText {
    font-size: 1rem;
  }
  .startBtn,
  .startBtnIndent {
    width: 20rem;
    font-size: 1.3rem;
    height: 4rem;
  }

  .startBtnIndentInner {
    width: 19.8rem;
    font-size: 1.3rem;
    height: 3.8rem;
  }

  .startBtnIndentInner {
    background: var(--color4);
    position: absolute;
    top: 1.4rem;
    left: 0.6rem;
    z-index: 1;
  }
  .productPoint {
    width: 90%;
    margin: 0;
  }
  .productTitleB {
    font-size: 1.6rem;
    margin: 0;
  }
  .productPoint p {
    font-size: 0.9rem;
    padding-top: 0.5rem;
  }
  .waitingListContainer p,
  .waitingListContainer small {
    width: 90%;
    font-size: 0.8rem;
  }

  .waitingListContainer {
    border-radius: 1rem;
    padding: 1rem;
  }

  .waitinglistInput {
    width: 95%;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin: 1rem 0;
    font-size: 0.8rem;
  }

  .signUpBtn {
    font-size: 0.8rem;
    padding: 0.5rem 1.6rem;
  }

  .accordion-body {
    font-size: 0.8rem;
  }
  .form {
    width: auto;
    margin: 2rem;
    padding: 2rem;
  }

  .questionSection,
  .productSection {
    height: auto;
  }

  .productSection {
    background: var(--color4);
    margin-top: 3rem;
    margin-bottom: 8rem;
  }
  .footerIconsContainer {
    flex-direction: column;
    padding-top: 1rem;
  }
  .iconDiv a svg {
    margin: 0.5rem;
  }
  .stayConnectedDiv {
    display: none;
  }
  .cookiesTitle {
    font-size: 1rem;
  }
  .CookiesSection p {
    font-size: 0.6rem;
  }
  .navlink {
    font-size: 1rem;
    padding: 1rem;
  }
  .myNav {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .waitingListContainer .text-danger {
    font-size: 0.8rem;
  }
  .footerIconsContainer img {
    margin-bottom: 1rem;
  }

  .contactSection {
    padding-top: 0rem;
  }

  .answerOptions {
    font-size: 0.8rem;
  }
  .waitingListTitle {
    margin-bottom: 3rem;
  }

  .FAQTitle {
    margin-top: 5rem;
  }

  .waitingListTitle,
  .FAQTitle {
    font-size: 1.8rem;
  }
}

@media (min-width: 751px) {
  .startBtnIndentInner:hover {
    background: var(--color4);
    position: absolute;
    top: 1.4rem;
    left: 0.6rem;
    z-index: 1;
  }
  .signUpBtn:hover {
    background: white;
    color: black;
  }
  .startBtnIndentInner:hover {
    /* opacity: 0; */
    border-radius: 0;
    background: var(--color5);
    font-weight: bold;
    border: none;
    width: 14.9rem;
    height: 2.9rem;
    position: relative;
    clip-path: polygon(0 0, 95% 0, 100% 20%, 100% 100%, 5% 100%, 0 78%);
    text-transform: uppercase;
    z-index: 2;
  }
  .startBtn:hover {
    top: 1.3rem; /* Offset from the top */
    left: 0.5rem;
    background: white;
    color: black;
  }
  .startBtnIndentInner:hover {
    position: absolute;
    top: 1.35rem; /* Offset from the top */
    left: 0.55rem; /* Offset from the left */
    z-index: 1; /* Ensure it appears behind the startBtn */
  }
  .navlink:hover {
    color: var(--color2);
  }
  .faq-header:hover {
    border-color: white;
  }
  .iconDiv svg:hover path {
    fill: var(--color2);
  }
  .waitingListSmall {
    padding-top: 1rem;
  }
  .footerBtn:hover {
    color: var(--color2);
  }
  .productText {
    width: 40%;
  }

  .startBtn,
  .startBtnIndent {
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    width: 20rem;
    height: 4.5rem;
  }
  .startBtnIndentInner {
    opacity: 0;
  }
}
